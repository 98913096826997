@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap");

body {
  margin: 0;
  font-family: "Rubik";
  color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Minter {
  text-align: center;
  align-items: center;
}

input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  font-size: 36px;
  width: 100%;
  border-color: rgba(4, 4, 5, 0.1);
  line-height: 32px;
}

input:focus {
  outline: none;
  border-color: rgba(4, 4, 5, 0.4);
}

button {
  display: block;
  padding: 9px 16px;
  max-height: 40px;
  border-color: #254cdd;
  color: #254cdd;
  background-color: white;
  border-radius: 8px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}

#title {
  padding-top: 32px;
}

#status {
  color: red;
}

#walletButton {
  margin: auto;
  margin-top: 0rem;
  margin-bottom: 50px;
  display: block;
  line-height: 16px;
}

#mintButton {
  margin: auto;
  margin-top: 50px;
  display: block;
  background-color: #254cdd;
  color: white;
  width: 200px;
}

input[type="number"] {
  width: 70px;
}
